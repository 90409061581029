import React, { Component, useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import StickyActionFooter from "../page-content/sticky-action-footer"
import ContentFold from "../page-content/content-fold"
import Medicines from "./medicines"
import Subtotal from "./subtotal"
import { pushCartDetails } from "../cart/actions"
import styles from "./cart.module.scss"
import RequiredDocuments from "./required-documents"
import pdfIcon from "../../assets/images/pdf.png"

import { AppContext } from "../../context/AppContext"
import AddMedicineModal from "./AddMedicineModal"

const ENTRESTO_QUERY = graphql`
  query EntrestoCoupons {
    allProducts {
      nodes {
        productTitle
        priceList {
          price_list
          vatex_unit_price
          vat
        }
        strength
        brand
        molecule
        form
      }
    }
    allDiscountCoupons {
      nodes {
        code
        alternatingQuantity
        id
        discountType
        productInclusions {
          title
        }
        freeProductInclusions {
          title
          type
          quantity
        }
        websiteDescription
        discountType
        percentOff
        minPurchase {
          quantity
        }
      }
    }
  }
`

const Cart = ({ t }) => {
  const data = useStaticQuery(ENTRESTO_QUERY)
  const { state, dispatch } = useContext(AppContext)
  const { cart, filesArray: dropzoneFiles } = state
  const canCheckout = () => dropzoneFiles.length > 0 && cart.details.length > 0

  const showCartModal = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Calculate your Medicine Assistance",
        isCard: true,
        headerClass: `has-text-primary has-background-light is-size-5`,
        hideCloseButton: state.cart.details.length === 0,
        content: <AddMedicineModal data={data} />,
      },
    })
  }

  const handleRemoveMedicine = ({ strength }) => {
    let cartDetails = [...cart.details]
    cartDetails = cartDetails.filter(
      product => !product.strength.includes(strength)
    )
    let subtotal = cartDetails.reduce((subtotal, product) => {
      return subtotal + parseFloat(product.quantity * product.vatex_unit_price)
    }, 0)

    dispatch({
      type: "SET_CART_DETAILS",
      payload: { ...cart, details: cartDetails, subtotal },
    })
  }

  useEffect(() => {
    if (cart.details.length === 0) showCartModal()
  }, [])

  return (
    <ContentFold title="Cart" isCentered>
      <RequiredDocuments fileCount={dropzoneFiles.length}>
        {dropzoneFiles.length === 0 && (
          <div className="p-1">
            <p className="has-text-centered help is-size-6">
              {t("You have no documents in your list.")} <br />
              <Link to="/document-upload">
                <span className="is-underlined has-text-weight-bold">
                  {t("Upload your prescription")}
                </span>
              </Link>
            </p>
          </div>
        )}
        {dropzoneFiles.length > 0 && (
          <div
            className={classNames(
              styles.deliveryDetails,
              styles.imageThumbnailContainer
            )}
          >
            {dropzoneFiles.map(fileURL => {
              const isPDF =
                fileURL.buffer.substring(
                  "data:".length,
                  fileURL.buffer.indexOf(";base64")
                ) === "application/pdf"
              return (
                <Link to="document-upload">
                  <div className={classNames(styles.img64)}>
                    <img
                      src={isPDF ? pdfIcon : fileURL.buffer}
                      className={
                        isPDF
                          ? classNames(styles.pdf, "image is-64x64")
                          : "image is-64x64"
                      }
                      alt="document"
                    />
                  </div>
                </Link>
              )
            })}
            <div classNames={classNames(styles.buttonAddFile)}>
              <Link to="/document-upload">
                <button className={classNames(styles.buttonAddFile)}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </Link>
            </div>
          </div>
        )}
      </RequiredDocuments>
      <div className="columns is-mobile level">
        <h4 className="column has-text-weight-bold m-0 is-size-4">
          {t("Medicines")}
        </h4>
      </div>
      <Medicines
        products={cart.details}
        showCartModal={showCartModal}
        handleRemoveMedicine={handleRemoveMedicine}
      />

      <Subtotal>{cart.subtotal}</Subtotal>

      {dropzoneFiles.length === 0 && (
        <p className="has-text-centered help is-danger">
          {t("Upload required documents to proceed with your order.")}
        </p>
      )}

      {cart.details.length === 0 && (
        <p className="has-text-centered help is-danger">
          {t("Add medicines to proceed with your order.")}
        </p>
      )}

      <StickyActionFooter>
        <div className={classNames("columns field is-grouped w-100")}>
          <div className="control column">
            <button
              className="button w-100"
              onClick={() => navigate("/patient")}
            >
              {t("Back")}
            </button>
          </div>
          <div className="control column">
            <button
              className="button is-link w-100"
              onClick={() => {
                let tempCart = cart
                if (state.couponCode === "SCPWD") {
                  tempCart.discountAmount = cart.subtotal * 0.2
                } else {
                  tempCart.discountAmount = 0
                }
                dispatch({
                  type: "SET_CART_DETAILS",
                  payload: {
                    ...tempCart,
                    couponCode: state.couponCode,
                  },
                })
                navigate("/checkout")
              }}
              disabled={!canCheckout()}
            >
              {t("Checkout")}
            </button>
          </div>
        </div>
      </StickyActionFooter>
    </ContentFold>
  )
}

export default withTranslation()(Cart)
