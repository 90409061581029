import React from "react"
import classNames from "classnames"

import { useTranslation } from "react-i18next"
import TotalValue from "./total-value"
import styles from "./total-value.module.scss"

// const ErrorMessage = ({ text, isError }) => (
//   <>
//     <div
//       className={classNames({
//         'has-background-danger has-text-white': isError,
//       })}
//     >
//       <p className="has-text-centered">{text}</p>
//     </div>
//     <FooterButtons disabled />
//   </>
// )

const CalculatorResult = ({
  // prescribedQuantity,
  totalQuantity,
  purchaseQuantity,
  medicineAssistance,
  handleAddToCartClick,
  unfixButtons,
  unitPrice,
  total,
}) => {
  const { t } = useTranslation()
  if (!!totalQuantity) {
    return (
      <>
        <div className="mb-2">
          <div className="has-background-warning p-1">
            <table
              className={classNames(
                styles.table,
                "table has-background-warning is-fullwidth"
              )}
            >
              <tbody>
                <tr>
                  <td>{t("Medicines you will pay for")}:</td>
                  <td
                    className="has-text-right"
                    style={{ whiteSpace: "nowrap", verticalAlign: "bottom" }}
                    valign="bottom"
                  >
                    {totalQuantity}{" "}
                    {t("tablet", { count: parseInt(medicineAssistance) })}
                  </td>
                </tr>
                <tr>
                  <td className="has-text-weight-bold">
                    {t("Medicine Assistance you will get")}:
                  </td>
                  <td
                    className="has-text-right has-text-weight-bold"
                    style={{ whiteSpace: "nowrap", verticalAlign: "bottom" }}
                  >
                    {medicineAssistance}{" "}
                    {t("tablet", { count: parseInt(medicineAssistance) })}
                  </td>
                </tr>
                {/* <tr>
                      <td>{t('Unit Price')}:</td>
                      <td className="has-text-right">{`₱ ${unitPrice}`}</td>
                    </tr> */}
                <tr className={classNames(styles.total)}>
                  <td colSpan="2">
                    <p className="has-text-centered">
                      {t("Total medicines you will receive")}:
                    </p>
                    <h1 className="subtitle has-text-centered is-primary has-text-weight-bold">
                      {medicineAssistance + totalQuantity}{" "}
                      {t("tablet", { count: parseInt(medicineAssistance) })}
                    </h1>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <TotalValue
            unitPrice={unitPrice}
            total={total}
            purchaseQuantity={totalQuantity}
          />
        </div>
      </>
    )
  } else return null
}
export default CalculatorResult
