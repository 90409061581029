import React from "react"
import AssistanceCalculator from "../calculator/assistance-calculator"

const AddMedicineModal = ({ data }) => {
  return (
    <div>
      <AssistanceCalculator data={data} />
    </div>
  )
}

export default AddMedicineModal
