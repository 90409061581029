import React from "react"
import classNames from "classnames"
import styles from "./forms.module.scss"

const NumberInput = ({
  name,
  label,
  handleInputChange,
  step = 1,
  value,
  disabled,
}) => {
  const handleButtonClick = (event, type) => {
    event.target.value = parseInt(step * (type === "+" ? 1 : -1))
    handleInputChange(event, "step")
  }
  const handleFocus = event => {
    event.target.select()
  }
  const handleBlur = event => {
    const value = event.target.value
    if (value === "") {
      event.target.value = 0
      handleInputChange(event)
    }
  }

  const handleOnKeyPress = event => {
    if (isNaN(event.key)) event.preventDefault()
  }

  return (
    <>
      {label && (
        <label className={classNames(styles.label, "label")}>{label}</label>
      )}
      <div className="field has-addons has-addons-centered">
        <div className="control">
          <button
            name={name}
            className="button is-primary"
            type="button"
            onClick={event => handleButtonClick(event, "-")}
            tabIndex={-1}
            disabled={disabled}
          >
            -
          </button>
        </div>
        <div className={classNames(styles.numberInput, "control")}>
          <input
            type="number"
            className={"input has-text-centered"}
            name={name}
            value={value}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            onKeyPress={handleOnKeyPress}
            min="0"
          />
        </div>
        <div className="control">
          <button
            name={name}
            className="button is-primary"
            type="button"
            onClick={event => handleButtonClick(event, "+")}
            tabIndex={-1}
            disabled={disabled}
          >
            +
          </button>
        </div>
      </div>
    </>
  )
}

export default NumberInput
