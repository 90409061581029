// Query coupons
const findCouponDiscount = (details, coupons) => {
  const { productTitle } = details
  const includedCoupons = coupons.filter(coupon =>
    coupon.productInclusions.find(inclusion => inclusion.title === productTitle)
  )
  return includedCoupons || []
}

const calculatePercentageDiscount = (product, coupon) => {
  const { totalQuantity, vatex_unit_price: unitPrice } = product
  const discountCode = coupon.code
  if (discountCode === "SCPWD") {
    const { alternatingQuantity, minPurchase } = coupon
    let qualifiedQuantity = totalQuantity
    if (alternatingQuantity > 0 && minPurchase["quantity"] > 0)
      qualifiedQuantity =
        Math.floor(
          parseFloat(totalQuantity) / parseFloat(alternatingQuantity)
        ) * parseFloat(minPurchase["quantity"])
    const totalPrice = parseFloat(qualifiedQuantity) * parseFloat(unitPrice)
    let percentOff = parseFloat(coupon.percentOff || 100)
    const discountAmount = totalPrice * (percentOff / 100)
    percentOff = Math.round(percentOff, 2)
    return {
      type: "amount",
      code: coupon.code,
      amount: discountAmount,
      description: coupon.description,
      notes: coupon.notes,
      totalUseLimit: coupon.totalUseLimit,
    }
  }
}

const calculateFreeProductDiscounts = (product, coupon) => {
  const { totalQuantity, vatex_unit_price: unitPrice, product_title } = product
  const { quantity: minimumPurchase } = coupon.minPurchase
  let qualifiedQuantity = minimumPurchase
  const discountValues = []
  coupon.freeProductInclusions.forEach(
    ({ title, quantity: freeProductQuantity }) => {
      qualifiedQuantity =
        Math.floor(parseFloat(totalQuantity) / freeProductQuantity) *
        parseFloat(minimumPurchase)
      discountValues.push({
        type: "quantity",
        code: coupon.code,
        quantity: qualifiedQuantity,
        freeProduct: title,
        description: coupon.websiteDescription,
      })
    }
  )
  return discountValues
}

const calculateValueDiscounts = (product, coupon) => {
  const { alternatingQuantity, minPurchase } = coupon
  const { totalQuantity, vatex_unit_price: unitPrice, product_title } = product
  let qualifiedQuantity = product.quantity

  if (alternatingQuantity > 0 && minPurchase["quantity"] > 0)
    qualifiedQuantity =
      Math.floor(parseFloat(totalQuantity) / parseFloat(alternatingQuantity)) *
      parseFloat(minPurchase["quantity"])

  let multiplier = 1
  if (alternatingQuantity > 0 && minPurchase["quantity"] > 0)
    multiplier = qualifiedQuantity / minPurchase["quantity"]
  let totalPrice = qualifiedQuantity / product.vatex_unit_price
  let discountAmount = multiplier * coupon.maxAmount
  if (totalPrice < discountAmount) discountAmount = totalPrice
  return {
    type: "amount",
    code: coupon.code,
    amount: discountAmount,
    description: coupon.websiteDescription,
    notes: coupon.notes,
    totalUseLimit: coupon.totalUseLimit,
  }
}

const getDiscountValue = (details, coupons) => {
  const product = { ...details, ...details.product }
  const discountCoupons = findCouponDiscount(product, coupons)
  const discountValues = []
  discountCoupons.forEach(coupon => {
    const freeProductsDiscounts = calculateFreeProductDiscounts(product, coupon)
    discountValues.push(...freeProductsDiscounts)
  })
  return discountValues
}

export default getDiscountValue
