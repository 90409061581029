import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './total-value.module.scss'
import { formatNumber } from '../../utils/moneyFormat'

const TotalValue = ({
  unitPrice,
  total,
  purchaseQuantity,
  medicineAssistance,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <table
        className={classNames(styles.table, 'table is-fullwidth mt-1 mb-2')}
      >
        <tbody>
          <tr>
            <td>{t('Unit Price')}:</td>
            <td className="has-text-right">
              ₱ {formatNumber(unitPrice)}
              <span className="mx-1">×</span>
              {purchaseQuantity}
            </td>
          </tr>
          <tr className={classNames(styles.total)}>
            <td className="has-weight-bold">
              <strong>{t('Total')}:</strong>
            </td>
            <td className="has-text-right has-weight-bold">
              <strong>{`₱ ${total}`}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default TotalValue
