import React from 'react'
import classNames from 'classnames'
import styles from './page-content.module.scss'
import Media from 'react-media'

const StickyActionFooter = ({ children }) => (
  <Media query={{ maxWidth: `1087px` }}>
    {matches => (
      <div className={classNames({ [styles.formFooter]: matches }, 'py-1')}>
        {children}
      </div>
    )}
  </Media>
)

export default StickyActionFooter
