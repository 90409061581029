import React, { Fragment } from "react"
import classNames from "classnames"
import { formatNumber } from "../../utils/moneyFormat"
import styles from "./cart.module.scss"
import { withTranslation } from "react-i18next"

const Medicines = ({ products, t, showCartModal, handleRemoveMedicine }) => {
  return (
    <Fragment>
      <div className={classNames(styles.hasBorderBottom, "py-1")}>
        {products &&
          products.map((product, index) => (
            <Fragment>
              <div className={classNames("is-flex", styles["productTitle"])}>
                <p className="has-text-weight-bold">{product.productTitle} </p>
                <div>
                  {!product.productTitle.includes("Medicine Assistance") && (
                    <button
                      className="delete"
                      onClick={() =>
                        handleRemoveMedicine({
                          ...product,
                        })
                      }
                    />
                  )}
                </div>
              </div>
              <div className="columns is-mobile help" key={index}>
                <span className="column is-8 is-6-mobile is-size-6">
                  <span className="is-size-6">
                    {t("Quantity")}: {product.quantity}
                  </span>
                </span>
                <span className="column is-4-mobile has-text-right help is-size-6">{`₱ ${formatNumber(
                  parseFloat(product.quantity) *
                    parseFloat(product.vatex_unit_price)
                )}`}</span>
              </div>
              {product.productTitle.includes("Medicine Assistance") &&
                index < products.length - 1 && (
                  <hr className={classNames(styles["medicinesDivider"])} />
                )}
            </Fragment>
          ))}

        {products.length === 0 ? (
          <Fragment>
            <p className="has-text-centered help is-size-6">
              {" "}
              {t("You have no medicines in your list.")}
            </p>
            <p
              className="is-underlined is-clickable has-text-weight-bold has-text-centered"
              onClick={showCartModal}
            >
              {t("Add medicines")}
            </p>
          </Fragment>
        ) : (
          <button className="button is-text" onClick={showCartModal}>
            <span className="is-underlined">+ add more medicines</span>
          </button>
        )}
      </div>
    </Fragment>
  )
}

export default withTranslation()(Medicines)
