import React, { useContext } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"

import StickyActionFooter from "../../components/page-content/sticky-action-footer"
import { AppContext } from "../../context/AppContext"

const FooterButtons = ({ disabled, handleAddToCartClick, isSticky }) => {
  const { dispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const buttons = (
    <div
      className={classNames(
        "columns is-mobile",
        {
          "mt-1": !isSticky,
        },
        { "is-fullwidth": isSticky }
      )}
    >
      <div className="column is-6">
        <button
          className="button is-fullwidth px-3"
          onClick={() =>
            dispatch({
              type: "HIDE_MODAL",
            })
          }
        >
          {t("Back")}
        </button>
      </div>
      <div className="column is-6">
        <button
          className={classNames("button is-primary is-fullwidth px-3")}
          disabled={!!disabled}
          onClick={handleAddToCartClick}
        >
          {t("Add to Cart")}
        </button>
      </div>
    </div>
  )
  if (isSticky) return <StickyActionFooter>{buttons}</StickyActionFooter>
  else return buttons
}

export default FooterButtons
