import React from "react"
import classNames from "classnames"

const buttonColors = ["is-purple", "is-info", "is-success"]

const TabletButton = ({ calculator, product, index, handleInputChange }) => {
  const buttonClassName = {
    [`${buttonColors[index]}`]:
      calculator.product.productTitle === product.productTitle,
  }

  return (
    <button
      key={index}
      value={product.productTitle}
      className={classNames("button", buttonClassName)}
      name="product"
      onClick={handleInputChange}
    >
      {product.strength}
    </button>
  )
}

const TabletButtonGroup = ({ calculator, handleInputChange, data }) => {
  const filteredProducts = data.filter(
    product => !product.productTitle.includes("Medicine Assistance")
  )

  return (
    <div className="buttons has-addons select-dosage pb-0 mb-0">
      {filteredProducts.map((product, index) => (
        <TabletButton
          calculator={calculator}
          product={product}
          index={index}
          handleInputChange={handleInputChange}
        />
      ))}
      <span className="button is-text has-text-black" disabled>
        Tablet
      </span>
    </div>
  )
}

export default TabletButtonGroup
