import React, { Fragment } from "react"
import classNames from "classnames"
import { withTranslation } from "react-i18next"
import { hasBorderBottom } from "./cart.module.scss"

const RequiredDocuments = ({ children, fileCount, t }) => (
  <Fragment>
    <h4 className="has-text-weight-bold is-size-4">
      {t("Required Documents")} ({fileCount})
    </h4>
    <div className={classNames(hasBorderBottom, "py-1")}>{children}</div>
  </Fragment>
)

export default withTranslation()(RequiredDocuments)
