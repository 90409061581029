import React, { Fragment, useContext } from "react"
import { navigate } from "gatsby"
import NumberInput from "../forms/numberInput"
// import TotalValue from './total-value'

import CalculatorResult from "./calculator-result"
import { formatNumber } from "../../utils/moneyFormat"
import PropTypes from "prop-types"
import { withTranslation, Trans } from "react-i18next"
import FooterButtons from "./footer-buttons"
import { AppContext } from "../../context/AppContext"
import TabletButtonGroup from "./AssistanceCalculator/TabletButtonGroup"
import getDiscountValue from "./utils/discountCouponCalculation"

const AssistanceCalculator = ({ t, data }) => {
  const { state, dispatch, showToast } = useContext(AppContext)
  const { calculator, cart } = state

  const totalPayment = formatNumber(
    calculator.totalQuantity * calculator.product.vatex_unit_price
  )

  const handleAddToCartClick = async () => {
    const { molecule, strength, form, brand } = calculator.product || {}
    let transactionDetails = [...cart.details]
    let hasAlreadySelected = transactionDetails.find(product =>
      product.productTitle.includes(strength)
    )
    transactionDetails = transactionDetails.filter(
      product => !product.productTitle.includes(strength)
    )
    let selectedProduct = {
      ...calculator.product,
      quantity: calculator.totalQuantity,
    }

    let medicineAssistance = {
      productTitle: `${molecule} ${strength} ${form} (${brand} - Medicine Assistance)`,
      molecule: molecule,
      strength: strength,
      form: form,
      brand: `${brand} - Medicine Assistance`,
      quantity: 14,
      vatex_unit_price: "0.00",
      vat: "0.00",
    }

    const NO_SUBTOTAL = 0
    let currentSubtotal = parseFloat(
      selectedProduct.quantity * selectedProduct.vatex_unit_price
    )
    let finalSubtotal = hasAlreadySelected ? NO_SUBTOTAL : currentSubtotal

    let subtotal = (cart.subtotal || 0) + finalSubtotal

    medicineAssistance = {
      ...medicineAssistance,
      quantity: calculator.medicineAssistance,
    }

    if (calculator.medicineAssistance.quantity === 0)
      transactionDetails.push(selectedProduct)
    else transactionDetails.push(selectedProduct, medicineAssistance)

    await dispatch({
      type: "SET_CART_DETAILS",
      payload: { ...cart, details: transactionDetails, subtotal },
    })

    await showToast({
      color: "success",
      message: "Added to cart",
    })

    dispatch({
      type: "HIDE_MODAL",
    })
    dispatch({
      type: "CLEAR_CALCULATOR_DETAILS",
    })

    // navigate("/cart")
  }

  const handleInputChange = (event, type) => {
    let target = event.target
    let fieldName = target.name

    let { totalQuantity, purchaseQuantity, product } = calculator
    let value = 0

    purchaseQuantity = parseInt(target.value)

    if (type === "step")
      value = parseInt(calculator[fieldName]) + parseInt(target.value)
    else value = target.value

    // disable negative
    if (parseInt(value) < 0) value = 0

    if (fieldName === "totalQuantity") {
      totalQuantity = value
      calculator.product.totalQuantity = value
    }

    const discountValues = getDiscountValue(
      calculator,
      data.allDiscountCoupons.nodes
    )

    var medicineAssistance = discountValues.length
      ? discountValues[0].quantity
      : 0

    if (fieldName === "product") {
      product = data.allProducts.nodes.find(
        product => product.productTitle === target.value
      )
      product.quantity = totalQuantity
      product.vatex_unit_price =
        parseFloat(product.priceList.vatex_unit_price) +
        parseFloat(product.priceList.vat)
    }

    const payload = {
      totalQuantity,
      purchaseQuantity,
      medicineAssistance,
      product,
    }

    dispatch({
      type: "SET_CALCULATOR_DETAILS",
      payload,
    })
  }

  const sortedProducts = data.allProducts.nodes.sort(
    (a, b) => a.strength.replace("mg", "") - b.strength.replace("mg", "")
  )

  return (
    <Fragment>
      {/* <h2 className="has-text-weight-bold has-text-primary py-1 pb-3 has-text-centered is-size-2">
        {t("Calculate your Medicine Assistance")}
      </h2> */}
      <div className="field control">
        <label className="label">{t("My doctor prescribed")}:</label>
        <span className="button is-text has-text-black pl-0 pt-0" disabled>
          Sacubitril-Valsartan
        </span>
        <TabletButtonGroup
          calculator={calculator}
          handleInputChange={handleInputChange}
          data={sortedProducts}
        />
        {!calculator.product.productTitle && (
          <p className="help is-danger mb-2">
            Please select your prescribed medicine first
          </p>
        )}
      </div>
      <Fragment>
        <NumberInput
          name="totalQuantity"
          label={
            <div className="mt-2 has-text-left">
              <Trans i18nKey="I want to receive">
                I want to <strong>purchase</strong>
              </Trans>
              :
            </div>
          }
          value={calculator.totalQuantity}
          handleInputChange={handleInputChange}
          disabled={!calculator.product.productTitle}
          max={calculator.totalQuantity}
        />
        {calculator.totalQuantity < 14 && (
          <div className="mb-2 has-text-left">
            To receive 14 pieces of Medicine Assistance, please input <b>14</b>{" "}
            pieces.
          </div>
        )}
        <CalculatorResult
          error="WrongRemaining"
          totalQuantity={parseInt(calculator.totalQuantity || 0)}
          purchaseQuantity={parseInt(calculator.purchaseQuantity || 0)}
          medicineAssistance={parseInt(calculator.medicineAssistance || 0)}
          unitPrice={calculator.product.vatex_unit_price}
          total={totalPayment}
        />
        <FooterButtons
          handleAddToCartClick={handleAddToCartClick}
          disabled={!calculator.totalQuantity}
        />
      </Fragment>
    </Fragment>
  )
}

const assistanceCalculatorPropTypes = {
  medicineAssistance: PropTypes.number,
  totalQuantity: PropTypes.number,
  purchaseQuantity: PropTypes.number,
}

AssistanceCalculator.propTypes = assistanceCalculatorPropTypes

export default withTranslation()(AssistanceCalculator)
