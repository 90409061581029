import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Cart from '../components/cart/cart'

const CartPage = props => (
  <Layout location={props.location}>
    <SEO
      title="Cart"
      keywords={[
        `novartis`,
        `medgrocer`,
        `pulse care`,
        `entresto`,
        `sacubitril-valsartan`,
      ]}
    />
    <Cart />
  </Layout>
)

export default CartPage
