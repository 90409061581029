import React, { useContext } from "react"
import classNames from "classnames"
import styles from "./cart.module.scss"
import { withTranslation } from "react-i18next"

import { formatNumber } from "../../utils/moneyFormat"
import { AppContext } from "../../context/AppContext"

const Subtotal = ({ children, t }) => {
  const { state } = useContext(AppContext)
  const ASEPTIC_DELIVERY_FEE = state.cart.asepticDelivery

  return (
    <div className={classNames("columns mb-3", styles.calculations)}>
      <div className="column">
        <div
          className={classNames("columns is-mobile", styles.asepticDelivery)}
        >
          <h3 className="column pb-0 is-6-mobile has-text-weight-bold">
            Aseptic Delivery
          </h3>
          <h3 className="column pb-0 is-6-mobile has-text-right has-text-weight-bold">
            ₱ {formatNumber(ASEPTIC_DELIVERY_FEE)}
          </h3>
        </div>
        <p className="help">
          To continuously deliver your medicines safely, we provide our riders
          the necessary precautionary protective gear and materials for every
          delivery transaction.
        </p>
        <div className="columns is-mobile is-size-5 mt-1">
          <h3 className="column is-6-mobile has-text-weight-bold">
            {t("Subtotal")}
          </h3>
          <h3 className="column is-6-mobile has-text-right has-text-weight-bold">
            ₱ {formatNumber(children + ASEPTIC_DELIVERY_FEE)}
          </h3>
        </div>
        <p className="is-size-7-mobile">
          {t(
            "Medicine Assistance and total amount are not final until verified by our pharmacist via SMS."
          )}
        </p>
      </div>
    </div>
  )
}

export default withTranslation()(Subtotal)
